<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    >
    </dialog-form-list>
  </div>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const _that = this
    return {
      title: this.$t('accountPermissionManage.changeContactVisibleRange'),
      showDialogMultipleTree: false,
      defaultContent: [],
      renewalType: '',
      showRenewal:false,
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:允许参会人数设定
        {
          tag: 'el-select',
          label: this.$t('companyPermissionManage.setLimitedNumberOfAttendees'),
          prop: 'roomAccountsLimit',
          tagProps: {
            options: [
              {
                label: this.$t('companyPermissionManage.unlimited'),
                value: 'NO',
              },
              {
                label: 2,
                value: 2,
              },
              {
                label: 3,
                value: 3,
              },
              {
                label: 4,
                value: 4,
              },
              {
                label: 5,
                value: 5,
              },
              {
                label: 6,
                value: 6,
              },
              {
                label: 7,
                value: 7,
              },
              {
                label: 8,
                value: 8,
              },
              {
                label: 9,
                value: 9,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 11,
                value: 11,
              },
              {
                label: 12,
                value: 12,
              },
              {
                label: 13,
                value: 13,
              },
              {
                label: 14,
                value: 14,
              },
              {
                label: 15,
                value: 15,
              },
              {
                label: 16,
                value: 16,
              },
              {
                label: 17,
                value: 17,
              },
              {
                label: 18,
                value: 18,
              },
              {
                label: 19,
                value: 19,
              },
              {
                label: 20,
                value: 20,
              },
            ],
          },
        },
        // lang:通信并发数设定
        {
          tag: 'el-select',
          label: this.$t('companyPermissionManage.setSimultaneousCommunicationLimit'),
          prop: 'simultaneousCommunicationLimit',
          tagProps: {
            options: [
              {
                label: this.$t('companyPermissionManage.unlimited'),
                value: 'NO',
              },
              {
                label: 2,
                value: 2,
              },
              {
                label: 3,
                value: 3,
              },
              {
                label: 4,
                value: 4,
              },
              {
                label: 5,
                value: 5,
              },
              {
                label: 6,
                value: 6,
              },
              {
                label: 7,
                value: 7,
              },
              {
                label: 8,
                value: 8,
              },
              {
                label: 9,
                value: 9,
              },
              {
                label: 10,
                value: 10,
              },
              {
                label: 11,
                value: 11,
              },
              {
                label: 12,
                value: 12,
              },
              {
                label: 13,
                value: 13,
              },
              {
                label: 14,
                value: 14,
              },
              {
                label: 15,
                value: 15,
              },
              {
                label: 16,
                value: 16,
              },
              {
                label: 17,
                value: 17,
              },
              {
                label: 18,
                value: 18,
              },
              {
                label: 19,
                value: 19,
              },
              {
                label: 20,
                value: 20,
              },
            ],
          },
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          let roomAccountsLimit = 'NO'
          if(this.tableRow.companyPermission === null) {
            roomAccountsLimit = 'NO'
          } else if(this.tableRow.companyPermission
              && this.tableRow.companyPermission.roomAccountsLimit
              && this.tableRow.companyPermission.roomAccountsLimit !== 'YES') {
            roomAccountsLimit = 'NO'
          } else if(this.tableRow.companyPermission
              && this.tableRow.companyPermission.roomAccountsLimit
              && this.tableRow.companyPermission.roomAccountsLimit === 'YES'
              && this.tableRow.companyPermission.roomAccountsLimitNum) {
            roomAccountsLimit = this.tableRow.companyPermission.roomAccountsLimitNum
          }
          this.formData.roomAccountsLimit = roomAccountsLimit

          let simultaneousCommunicationLimit = 'NO'
          if(this.tableRow.companyPermission === null) {
            simultaneousCommunicationLimit = 'NO'
          } else if(this.tableRow.companyPermission
              && this.tableRow.companyPermission.simultaneousCommunicationLimit
              && this.tableRow.companyPermission.simultaneousCommunicationLimit !== 'YES') {
            simultaneousCommunicationLimit = 'NO'
          } else if(this.tableRow.companyPermission
              && this.tableRow.companyPermission.simultaneousCommunicationLimit
              && this.tableRow.companyPermission.simultaneousCommunicationLimit === 'YES'
              && this.tableRow.companyPermission.simultaneousCommunicationNum) {
            simultaneousCommunicationLimit = this.tableRow.companyPermission.simultaneousCommunicationNum
          }
          this.formData.simultaneousCommunicationLimit = simultaneousCommunicationLimit
        }
      })
    },
    confirm() {
      let formData = {
        companyUid: this.tableRow.uid,
        roomAccountsLimit: this.formData.roomAccountsLimit === 'NO' ? 'NO' : 'YES',
        roomAccountsLimitNum: this.formData.roomAccountsLimit === 'NO' ? null : this.formData.roomAccountsLimit,
        simultaneousCommunicationLimit: this.formData.simultaneousCommunicationLimit === 'NO' ? 'NO' : 'YES',
        simultaneousCommunicationNum: this.formData.simultaneousCommunicationLimit === 'NO' ? null : this.formData.simultaneousCommunicationLimit,
      }
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.updateCompanyPermission,
        data: formData,
      }).then(res => {
        this.$emit('closePage', 'confirm')
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
  },
}
</script>
