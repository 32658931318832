<template>
  <base-page
      :title="$t('companyPermissionManage.title')"
      :formData="formData"
      :config="config"
      @functionMethod="functionMethod"
      @search="search"
  >
    <table-list
        ref="tableList"
        :url="'/' + $util.getLocalStorage('apiprefix') + $api.findCompanyPermissionList"
        :defaultParams="params"
        :columns="columns"
        v-model="tableRow"
    >
    </table-list>
    <detail v-if="showDetail" :tableRow="tableRow" @closePage="closePage" />
  </base-page>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    let _that = this
    return {
      // 搜索
      formData: {},
      config: [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 6,
          tagProps: {
            placeholder: this.$t('companyPermissionManage.name'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      params: {
        needFullAddress: 'YES',
      },
      columns: [
        // lang: 企业名称
        {
          label: this.$t('companyPermissionManage.name'),
          prop: 'name',
        },
        // lang: 所属行业
        {
          label: this.$t('companyPermissionManage.industry'),
          prop: 'industry',
          callback(row) {
            return row.industry ? row.industry.value : _that.$t('companyPermissionManage.unknown')
          },
        },
        // lang: 联系人
        {
          label: this.$t('companyPermissionManage.contacts'),
          prop: 'contacts',
        },
        // lang: 联系方式
        {
          label: this.$t('companyPermissionManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang: 联系邮箱
        {
          label: this.$t('companyPermissionManage.email'),
          prop: 'email',
        },
        // lang: 参会人数限定
        {
          label: this.$t('companyPermissionManage.limitedNumberOfAttendees'),
          prop: 'companyPermission',
          callback(row) {
            if(row.companyPermission === null) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission && row.companyPermission.roomAccountsLimit && row.companyPermission.roomAccountsLimit !== 'YES') {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission
                && row.companyPermission.roomAccountsLimit
                && row.companyPermission.roomAccountsLimit === 'YES' && row.companyPermission.roomAccountsLimitNum) {
              return row.companyPermission.roomAccountsLimitNum
            }
            return _that.$t('companyPermissionManage.unlimited')
          },
        },
        // lang: 通信并发数限定
        {
          label: this.$t('companyPermissionManage.simultaneousCommunicationLimit'),
          prop: 'companyPermission',
          callback(row) {
            if(row.companyPermission === null) {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission && row.companyPermission.simultaneousCommunicationLimit && row.companyPermission.simultaneousCommunicationLimit !== 'YES') {
              return _that.$t('companyPermissionManage.unlimited')
            } else if(row.companyPermission
                && row.companyPermission.simultaneousCommunicationLimit
                && row.companyPermission.simultaneousCommunicationLimit === 'YES' && row.companyPermission.simultaneousCommunicationNum) {
              return row.companyPermission.simultaneousCommunicationNum
            }
            return _that.$t('companyPermissionManage.unlimited')
          },
        },
      ],
      // 表单页
      showDetail: false,
    }
  },
  mounted() {
  },
  methods: {
    // 关闭详情页回调
    closePage(method) {
      this.showDetail = false
      if (method && method !== 'cancel') {
        this.search()
      }
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if (formData.addressId) formData.addressId = formData.addressId.pop()
      this.$refs.tableList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      const { method } = item
      if (method !== 'add' && method !== 'import' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'permissionSetting':
          this.showDetail = true
          break
        default: // do something
      }
    },
  },
}
</script>
